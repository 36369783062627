import { Button, Dialog } from 'components/ui';
import { useState } from 'react';

interface DialogNotificacoesProps {
    titulo: string;
    texto: string;
    isOpen: boolean;
    onClose: () => void;
}

const DialogNotificacoes = ({ titulo, texto, isOpen, onClose }: DialogNotificacoesProps) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
            onClose();
        }, 300);
    };

    return (
        <Dialog overlayClassName="z-40" isOpen={isOpen} onClose={onClose} onRequestClose={onClose}>
            <div className="relative">
                <h6 className="mb-4 font-medium">{titulo}</h6>
                <div className="mb-4">
                    <div dangerouslySetInnerHTML={{ __html: texto }} />
                </div>
                <div className="text-right mt-6 flex flex-col md:flex-row gap-2">
                    <Button variant="solid" onClick={handleClose} disabled={isLoading}>
                        Fechar
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DialogNotificacoes;
