import ApiService from "./ApiService";

export async function _buscarNotificacoes(ultimaNotificacaoId) {
  return ApiService.fetchData({
    url: `/rest/notificacao/${ultimaNotificacaoId}`,
    method: "get",
  });
}

export async function _salvarNotificacao(dados) {
  return ApiService.fetchData({
    url: "/rest/notificacao",
    method: "post",
    data: dados,
  });
}

export async function _salvarNotificacaoLidas(ids) {
  return ApiService.fetchData({
    url: "/rest/notificacao/lidas",
    method: "post",
    data: ids,
  });
}

export async function _deletarNotificacaoId(ids) {
  return ApiService.fetchData({
    url: `/rest/notificacao/byId`,
    method: "delete",
    data: ids,
  });
}

export async function _deletarNotificacoesTipo(tipo) {
    return ApiService.fetchData({
      url: `/rest/notificacao/${tipo}`,
      method: "delete",
    });
  }
  