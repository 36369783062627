import {
    NAV_ITEM_TYPE_COLLAPSE,
    NAV_ITEM_TYPE_ITEM,
    NAV_ITEM_TYPE_TITLE
} from 'constants/navigation.constant';

const navigationConfig = [
    {
        key: 'dashboard',
        path: '/dashboard',
        title: 'Início Rápido',
        icon: 'dashboard',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
    },
    {
        key: 'agenda',
        path: '/calendario',
        title: 'Agenda',
        icon: 'calendario',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
    },
    {
        key: 'meusClientes',
        path: '/meusClientes',
        title: 'Pacientes',
        icon: 'clientes',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
    },
    {
        key: 'financeiro.lancamentos',
        path: '/lancamentos',
        title: 'Financeiro',
        icon: 'financeiro',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
    },
    {
        key: 'onboarding',
        path: '',
        title: 'Cobrança Automática',
        icon: 'financeiro2',
        type: NAV_ITEM_TYPE_COLLAPSE,
        authority: [],
        subMenu: [
            {
                key: 'cobrança',
                path: '/onboarding',
                title: 'Ativar meio de pagamento',
                icon: 'financeiro2',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'cobrancaInfo',
                path: '/cobrancaInfo',
                title: 'Configurar',
                icon: 'financeiro2',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
        ],
    },
    {
        key: 'configs',
        path: '',
        title: 'Site de Agendamento',
        icon: 'config',
        type: NAV_ITEM_TYPE_COLLAPSE,
        authority: [],
        subMenu: [
            {
                key: 'configs.links',
                path: '/links',
                title: 'Links',
                icon: 'links',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'configs.localidade',
                path: '/localidades',
                title: 'Locais de Atendimento',
                icon: 'calendario',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'configs.servico',
                path: '/servicos',
                title: 'Serviços',
                icon: 'calendario',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'configs.agenda',
                path: '/configAgenda',
                title: 'Horários de Atendimento',
                icon: 'calendario',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },
            {
                key: 'configs.perfil',
                path: '/configuracoes',
                title: 'Controles e Políticas',
                icon: 'calendario',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            },            
            
            // {
            //     key: 'configs.feedback',
            //     path: '/feedback',
            //     title: 'Feedback de Pacientes',
            //     icon: 'calendario',
            //     type: NAV_ITEM_TYPE_ITEM,
            //     authority: [],
            //     subMenu: [],
            // },
        ],
    },
    {
        key: 'users',
        path: '/gerenciamentoUsuarios',
        title: 'Gerenciamento de usuários',
        icon: 'usuarios',
        type: NAV_ITEM_TYPE_COLLAPSE,
        authority: [],
        subMenu: [
            {
                key: 'usuarios.secretarias',
                path: '/secretarias',
                title: 'Secretárias',
                icon: 'usuarios',
                type: NAV_ITEM_TYPE_ITEM,
                authority: [],
                subMenu: [],
            }
        ]
    },
    {
        key: 'configs.importacoes',
        path: '/importacoes',
        title: 'Importação de Dados',
        icon: 'calendario',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
    },
    {
        key: 'modelos',
        path: '/modelos',
        title: 'Modelos de Documentos',
        icon: 'modelos',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
    },
    {
        key: 'tecnicas',
        path: '/tecnicas',
        title: 'Formulários & Técnicas',
        icon: 'ia',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
    },
    {
        key: 'marketing',
        path: '/marketing',
        title: 'Marketing',
        icon: 'marketing',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
        link: 'https://corpora.notion.site/M-dulo-de-Marketing-117cb16d1f8c80ada185f55d0ed7f8ac',
        // subMenu: [
        //     {
        //         key: 'marketing.arquivos',
        //         path: '/marketing/calendario',
        //         title: 'Calendários',
        //         icon: 'arquivos',
        //         type: NAV_ITEM_TYPE_ITEM,
        //         authority: [],
        //         subMenu: [],
        //     },
        //     {
        //         key: 'marketing.canva',
        //         path: '/marketing/canva',
        //         title: 'Canva',
        //         icon: 'canva',
        //         type: NAV_ITEM_TYPE_ITEM,
        //         authority: [],
        //         subMenu: [],
        //     },
        // ],
    },
    {
        key: 'educacao',
        path: '/educacao',
        title: 'Educacional',
        icon: 'educacao',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
        link: 'https://corpora.notion.site/Marketing-17a0097658bd41adb69499ec35d15c71',
    },
    {
        key: 'indique',
        path: '/indique',
        title: 'Indique & Ganhe',
        icon: 'indique',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
    },
    {
        key: 'sair',
        path: '/sair',
        title: 'Sair',
        icon: 'logout',
        type: NAV_ITEM_TYPE_ITEM,
        authority: [],
        subMenu: [],
    },
];

export default navigationConfig;
