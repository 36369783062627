import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { _buscarNotificacoes, _salvarNotificacaoLidas } from 'services/NotificacaoService';
import navigationConfig from 'configs/navigation.config';
import DialogNotificacoes from 'components/ui/DialogNotificacoes';


interface Notificacao {
    id: number;
    titulo: string;
    texto: string;
    tipo: string;
    url: string[];  
    status: string;
}

interface NotificacaoContextProps {
    notificacoes: Notificacao[];
    notificacaoAtual: Notificacao | null;
    isDialogOpen: boolean;
    fecharDialog: () => void;
}

const NotificacaoContext = createContext<NotificacaoContextProps | undefined>(undefined);

export const NotificacaoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [notificacoes, setNotificacoes] = useState<Notificacao[]>([]);
    const [notificacaoAtual, setNotificacaoAtual] = useState<Notificacao | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const location = useLocation();
    

    useEffect(() => {
        if (location.pathname === "/sign-in") return;

        const buscarNotificacoes = async () => {
            try {
                const response = await _buscarNotificacoes(0);
                if (response && response.data) {
                    const notificacoesFiltradas = response.data
                        .map((notificacao: any) => ({
                            ...notificacao,
                            url: JSON.parse(notificacao.url || '[]'), 
                        }))
                        .filter((notificacao: Notificacao) => notificacao.tipo === 'POPUP'); 

                    setNotificacoes(notificacoesFiltradas);
                }
            } catch (error) {
                console.error('Erro ao buscar notificações:', error);
            }
        };

        buscarNotificacoes();
    }, []);

    useEffect(() => {
        if (notificacoes.length === 0) return;

        const notificacaoEncontrada = notificacoes.find(
            (notificacao) =>
                notificacao.status === 'NAO_LIDA' &&
                notificacao.url.some((chave) =>
                    navigationConfig.some((rota) => rota.key === chave && rota.path === location.pathname)
                )
        );

        if (notificacaoEncontrada) {
            setNotificacaoAtual(notificacaoEncontrada);
            setIsDialogOpen(true);
        }
    }, [location.pathname, notificacoes]);

    const fecharDialog = async () => {
        if (notificacaoAtual) {
            try {
                await _salvarNotificacaoLidas({ ids: [notificacaoAtual.id] });
                setNotificacoes((prev) =>
                    prev.map((n) => (n.id === notificacaoAtual.id ? { ...n, status: 'LIDA' } : n))
                );
            } catch (error) {
                console.error('Erro ao marcar notificação como lida:', error);
            }
        }

        setIsDialogOpen(false);
        setNotificacaoAtual(null);
    };

    return (
        <NotificacaoContext.Provider value={{ notificacoes, notificacaoAtual, isDialogOpen, fecharDialog }}>
            {children}
            {notificacaoAtual && (
                <DialogNotificacoes
                    titulo={notificacaoAtual.titulo}
                    texto={notificacaoAtual.texto}
                    isOpen={isDialogOpen}
                    onClose={fecharDialog}
                />
            )}
        </NotificacaoContext.Provider>
    );
};

export const useNotificacao = (): NotificacaoContextProps => {
    const context = useContext(NotificacaoContext);
    if (!context) {
        throw new Error('useNotificacao deve ser usado dentro de um NotificacaoProvider');
    }
    return context;
};
