import ApiService from './ApiService';

export async function _atualizarAgenda(agendaId, dados, alterarSequencia) {
    return ApiService.fetchData({
        url: `/rest/agenda/${agendaId}/${alterarSequencia}`,
        method: 'put',
        data: dados,
    });
}

export async function _buscarAgenda() {
    return ApiService.fetchData({
        url: '/rest/agenda',
        method: 'get',
    });
}

export async function _salvarAgenda(dados) {
    return ApiService.fetchData({
        url: '/rest/agenda/cliente',
        method: 'post',
        data: dados,
    });
}

export async function _buscarAgendaDatas(dataInicio, dataFim) {
    return ApiService.fetchData({
        url: `/rest/agenda/${dataInicio}/${dataFim}`,
        method: 'get',
    });
}

// TIPOEXCLUSAO: 0 - SOMENTE_AGENDA, 1 - AGENDAS_A_FRENTE, 2 - TODAS_AGENDAS
export async function _apagarAgenda(agendaId, tipoExclusao) {
    return ApiService.fetchData({
        url: `/rest/agenda/${agendaId}/${tipoExclusao}`,
        method: 'delete',
    });
}

export async function _iniciarReuniaoAgenda(idAgenda) {
    return ApiService.fetchData({
        url: `/rest/agenda/iniciarReuniao/${idAgenda}`,
        method: 'get',
    });
}

export async function _buscarAgendaCliente(clienteId) {
    return ApiService.fetchData({
        url: `/rest/agenda/cliente/${clienteId}`,
        method: 'get',
    });
}

export async function _realizarAgenda(agendaId, cobrar) {
    return ApiService.fetchData({
        url: `/rest/agenda/${agendaId}/realizar/${cobrar}`,
        method: 'put',
    });
}

export async function _naoCompareceuAgenda(agendaId, cobrar, enviarMensagem) {
    return ApiService.fetchData({
        url: `rest/agenda/${agendaId}/naoCompareceu/${cobrar}/${enviarMensagem}`,
        method: 'put',
    });
}

// TIPOEXCLUSAO: 0 - SOMENTE_AGENDA, 1 - AGENDAS_A_FRENTE
export async function _cancelamentoAgenda(agendaId, responsavel = 1, cobrar, cancelarAgendasSeguintes, enviarMensagem) {
    return ApiService.fetchData({
        url: `rest/agenda/${agendaId}/cancelar/${responsavel}/${cobrar}/${cancelarAgendasSeguintes}/${enviarMensagem}`,
        method: 'put',
    });
}

export async function _reagendar(agendaId) {
    return ApiService.fetchData({
        url: `rest/agenda/${agendaId}/reagendar`,
        method: 'put',
    });
}

export async function _recuperarAgenda(agendaId) {
    return ApiService.fetchData({
        url: `rest/agenda/${agendaId}/desfazer`,
        method: 'put',
    });
}

export async function _sumarioAgenda() {
    return ApiService.fetchData({
        url: 'rest/agenda/sumarizar',
        method: 'get',
    });
}

export async function _sumarioAgendasRealizadas() {
    return ApiService.fetchData({
        url: 'rest/agenda/sumarizarRealizadas',
        method: 'get',
    });
}

export async function _buscarAgendaLancamento(idLancamento) {
    return ApiService.fetchData({
        url: `/rest/agenda/lancamento/${idLancamento}`,
        method: 'get',
    });
}
